import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-receba-ligacao',
  templateUrl: './receba-ligacao.component.html',
  styleUrls: ['./receba-ligacao.component.scss']
})
export class RecebaLigacaoComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
