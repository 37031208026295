import { Component, OnInit } from '@angular/core';
import {environment} from '../../../../environments/environment';

@Component({
  selector: 'app-em-construcao',
  templateUrl: './em-construcao.component.html',
  styleUrls: ['./em-construcao.component.scss']
})
export class EmConstrucaoComponent implements OnInit {


  public production = environment.production;

  constructor() { }

  ngOnInit(): void {
  }

}
