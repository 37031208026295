<!-- success section start -->
<section class="ptb-100">
  <div class="container">
    <div class="row">
      <div class="col-lg-12">
        <div class="sec-title mb-20">
          <h2>Nossos <span class="title-span">planos.</span>
            <span class="sec-title-border">
									<span></span>
									<span></span>
									<span></span>
								</span>
          </h2>
        <!-- <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>-->
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-6">
        <div class="single-success-box">
          <h4>Conheça um pouco sobre nossos <span>planos</span>.</h4>
          <h5><i class="icofont icofont-check-circled"></i> Plano Essencial</h5>
          <p>Visa atender as necessidades básicas do condomínio. (Plano Mensal)</p>
          <h5><i class="icofont icofont-check-circled"></i> Plano Exclusive </h5>
          <p>É formado de acordo com as necessidades e demandas de cada condomínio
            (Plano Mensal)</p>
          <h5><i class="icofont icofont-check-circled"></i> Plano Premium </h5>
          <p>Contempla todos os nossos serviços em um único pacote, é um plano completo para quem busca segurança, comodidade e valorização do seu condomínio.
            (Plano Mensal)</p>

          <h5>Contrate o Plano Exclusive ou Premium e ganhe cortesias para pequenos reparos dos seguintes profissionais:
            Pedreiro, pintor e eletricista</h5>

        <!--<a href="javascript;" routerLink="/planos" class="theme-btn theme-btn5">Saiba Mais</a>-->
        </div>
      </div>
      <div class="col-lg-5 offset-lg-1">
        <div class="single-success-box">
          <img src="assets/img/success.png" alt="" />
        </div>
      </div>
    </div>
  </div>
</section>
<!-- success section end -->
