import {Component, OnInit} from '@angular/core';
import {Util} from './../../../shared/util';

@Component({
  selector: 'app-servicos',
  templateUrl: './servicos.component.html',
  styleUrls: ['./servicos.component.scss']
})
export class ServicosComponent implements OnInit {

  constructor() {
  }

  ngOnInit(): void {
    Util.scrollTop();
  }

}
