// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  info: {
    email: 'atendimento@gprimeservice.com.br',
    telefone: '(61) 2194-8309',
    celular: '(61) 9 8378-2693',
    whatsapp: '+5561983782693',
    endereco: 'Rua Copaíba Lt 01 Torre B, salas 207 parte A Águas Claras, DF Cep 71919540',
    facebook: '#',
    twitter: '#',
    youtube: '#'
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
