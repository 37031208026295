import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {ObrasRealizadasComponent} from './views/pages/obras-realizadas/obras-realizadas.component';
import {HomeComponent} from './views/pages/home/home.component';
import {ContatosComponent} from './views/pages/contatos/contatos.component';
import {ObrasEmAndamentoComponent} from './views/pages/obras-em-andamento/obras-em-andamento.component';
import {ServicosComponent} from './views/pages/servicos/servicos.component';
import {QuemSomosComponent} from './views/pages/quem-somos/quem-somos.component';
import {EmConstrucaoComponent} from './views/pages/em-construcao/em-construcao.component';
import {PlanosComponent} from './views/pages/planos/planos.component';

const routes: Routes = [
  // {path: 'auth', loadChildren: () => import('app/views/pages/auth/auth.module').then(m => m.ObrasRealizadasComponent)},
  // {path: '', component: EmConstrucaoComponent},
  {path: '', component: HomeComponent},
  {path: 'quem-somos', component: QuemSomosComponent, data: {title: 'Quem somos?'}},
  {path: 'obras-realizadas', component: ObrasRealizadasComponent, data: {title: 'Obras Realizadas'}},
  {path: 'obras-em-andamento', component: ObrasEmAndamentoComponent, data: {title: 'Obras em Andamento'}},
  {path: 'contato', component: ContatosComponent, data: {title: 'Entre em Contato'}},
  {path: 'servicos', component: ServicosComponent, data: {title: 'Nossos Serviços'}},
  {path: 'planos', component: PlanosComponent, data: {title: 'Planos'}},
  {path: 'em-construcao', component: EmConstrucaoComponent, data: {title: 'Em Construção'}},
  {path: '**', redirectTo: 'error:403', pathMatch: 'full'}
];
@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
