import {AfterViewInit, Component, OnInit} from '@angular/core';

declare var $: any;

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss']
})
export class AboutComponent implements OnInit, AfterViewInit {

  constructor() {
  }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    this.init();
  }

  private init(): void {
    $('.popup-youtube').magnificPopup({type: 'iframe'});
    // iframe scripts
    $.extend(true, $.magnificPopup.defaults, {
      iframe: {
        patterns: {
          // youtube videos
          youtube: {
            index: 'youtube.com/',
            id: 'v=',
            src: 'https://www.youtube.com/embed/%id%?autoplay=1'
          }
        }
      }
    });
  }
}



