<section class="hero-area" id="home">
  <div class="container">
    <div class="row">
      <div class="col-lg-12">
        <div class="hero-area-slider">
          <div class="hero-area-content">
            <h1>Seu <span>Condomínio</span> em boas mãos!</h1>
            <h4>ACABE COM A DESORGANIZAÇÃO FINANCEIRA DO SEU CONDOMÍNIO.</h4>
            <a href="javascript;" routerLink="/servicos" class="theme-btn theme-btn8">SAIBA MAIS</a>
            <a href="javascript;" routerLink="/servicos" class="theme-btn theme-btn5">CONTATE-NOS</a>
          </div>
<!--          <div class="hero-area-content">-->
<!--            <h3>Administração de <span>condomínios</span></h3>-->
<!--            <h4>Fomentada da necessidade de uma gestão de excelência na administração de condomínios.</h4>-->
<!--            <a href="javascript;" routerLink="/servicos" class="theme-btn theme-btn8">SAIBA MAIS</a>-->
<!--            <a href="javascript;" routerLink="/servicos" class="theme-btn theme-btn5">CONTATE-NOS</a>-->
<!--          </div>-->
        </div>
      </div>
    </div>
  </div>
</section>
