<section class="our-about ptb-100">
  <div class="container">
    <div class="row">
      <div class="col-lg-6">
        <div class="video-popup">
          <a href="https://www.youtube.com/watch?v=-cWBn2nmEV0" class="popup-youtube"><i
            class="icofont icofont-ui-play"></i></a>

          <div class="col-md-12 valores">
            <div class="row">
              <div class="item col-md-2">
                <div class="icones"><!--?xml version="1.0" encoding="UTF-8"?-->
                  <svg preserveAspectRatio="xMidYMid meet" data-bbox="0 0.01 87.14 86.97" viewBox="0 0.01 87.14 86.97" xmlns="http://www.w3.org/2000/svg" data-type="shape" role="presentation" aria-hidden="true">
                    <g>
                      <g>
                        <path d="M27.06 78.52c.15 1.13 1 .85 1.62.7s1.3-.3 1.21-1.21V49.46c0-1.53.4-2 1.92-1.91 4.63.12 9.26.07 13.89.09l.4.34c.16 10.18.18 20.36 0 30.55-.26.12-.28.25 0 .39 2.52.9 3 .64 3-2V35.76c0-1.5.36-1.82 1.79-1.79 4.18.09 8.37.11 12.56 0 1.58 0 2 .38 2 2-.07 13.84 0 27.69 0 41.54 0 .59-.34 1.46.46 1.68s1.82.42 2.34-.69c.65-.64.35-1.46.35-2.2V23.43c0-2.56 0-2.56 2.64-2.56h11c.62 0 1.31.22 1.82-.34.83.48.38 1.28.38 1.9v54.1c0 2.76 0 2.76 2.7 2.77v2.32H13.71c-1.78 0-4.14-.8-5.17.36-.88 1-.27 3.31-.32 5h-2.8c0-1.72.57-4-.3-5-1-1.19-3.36-.25-5.12-.4v-2.27h2.9c2.51 0 2.51 0 2.51-2.56V.01h2.33v76.87c0 .68-.25 1.42.32 2 2.18.86 2.78.5 2.78-1.72V62.68c0-.77-.14-1.56 1.1-1.54 4.89.05 9.78 0 14.67.05.14 0 .27.21.41.33a82.36 82.36 0 00.04 17zm-13.52.35c1.13.88 2.46.43 3.67.4 2.39-.08 4.81.49 7.16-.35l.24-.43c.57-.17.53-.64.53-1.09V65.34c0-.46 0-.92-.55-1.08l-.22-.41c-.48-.62-1.17-.43-1.77-.39-2.89.19-5.8-.48-8.66.35l-.43.42a1.2 1.2 0 00-.36 1v12.51c0 .45 0 .9.44 1.18zm68.09 0c.7-.53.42-1.3.42-2V25.13c0-.67.27-1.43-.37-2-3.15 0-6.3 0-9.45-.08-1.06 0-1.46.26-1.45 1.39q.06 27.2.07 54.39c3 .9 6.18.16 9.26.39.5.12 1.12.25 1.52-.35zm-38.31-28.6a30.64 30.64 0 00-10.42 0c-.46.32-.32.8-.32 1.23v26.23c0 .43-.14.91.32 1.23 2 .77 4.11.28 6.16.32 1.41 0 2.9.46 4.26-.33.37-.36.26-.83.26-1.27V51.55c0-.45.11-.92-.26-1.28zm19.37 28.25c.54-.44.36-1.06.36-1.61v-38.4c0-.6.25-1.32-.47-1.74-3 0-6 .07-9.07-.05-1.51-.06-1.94.37-1.93 1.91.06 12.8 0 25.61 0 38.41a3.69 3.69 0 00.22 1.89 30.19 30.19 0 0010.43 0z"></path>
                        <path d="M8.07 78.9c-.57-.61-.32-1.35-.32-2V.03h.38v76.74c-.01.71-.05 1.42-.06 2.13z"></path>
                        <path d="M73.5 9.67c-2.21 1-4 2.58-5.9 3.95l-47.66 34c-.52.37-1 1.56-2 .38-.82-1-1-1.55.28-2.48q20.33-14.41 40.57-28.95l13-9.32c-1.64-.56-3-1-4.36-1.45-.89-.26-1.26-.63-.88-1.62s.68-1.39 1.79-1c2.23.83 4.51 1.53 6.78 2.26A1.32 1.32 0 0176.21 7c-.07 2 0 4 0 6 0 2.3-.18 2.41-2.6 1.69a7.56 7.56 0 00-.11-5.02z"></path>
                        <path d="M83.93 20.54c-.51.56-1.2.34-1.82.34h-11c-2.64 0-2.64 0-2.64 2.56v52.87c0 .74.3 1.56-.35 2.2q0-17.2-.06-34.42v-22c0-1.27.34-1.64 1.61-1.61 4.73.06 9.5.04 14.26.06z"></path>
                        <path d="M27.06 78.52a82.36 82.36 0 010-17z"></path>
                        <path d="M73.5 9.67a7.56 7.56 0 01.09 5z"></path>
                        <path d="M46.07 48l-.4-.34c.25-.04.42.03.4.34z"></path>
                        <path d="M46.05 78.92c-.27-.14-.25-.27 0-.39z"></path>
                        <path d="M81.58 78.92V23.21c.64.57.37 1.33.37 2Q82 51.09 82 77c0 .62.28 1.39-.42 1.92z"></path>
                        <path d="M62.69 78.52l-.06-36.73c0-1.67 0-3.35-.05-5 .72.42.47 1.14.47 1.74v38.4c0 .53.18 1.15-.36 1.59z"></path>
                        <path d="M24.61 78.49c-.19-4.74-.15-9.49 0-14.23.57.16.55.62.55 1.08V77.4c-.02.45.02.92-.55 1.09z"></path>
                        <path d="M13.51 64.23q0 7.35.08 14.69c-.46-.28-.44-.73-.44-1.18V65.27a1.2 1.2 0 01.36-1.04z"></path>
                        <path d="M13.54 78.87h10.83c-2.35.84-4.77.27-7.16.35-1.21.08-2.54.53-3.67-.35z"></path>
                        <path d="M70.8 78.91h10.83c-.4.6-1 .47-1.57.43-3.06-.27-6.21.47-9.26-.43z"></path>
                        <path d="M43.32 50.27H32.9a30.64 30.64 0 0110.42 0z"></path>
                        <path d="M24.37 63.85H13.94c2.86-.83 5.77-.16 8.66-.35.6-.08 1.29-.27 1.77.35z"></path>
                        <path d="M32.9 79h10.42c-1.36.79-2.85.35-4.26.33-2.06-.1-4.14.39-6.16-.33z"></path>
                        <path d="M51.84 78.93h10.43a30.19 30.19 0 01-10.43 0z"></path>
                      </g>
                    </g>
                  </svg>
                </div>
              </div>
              <div class="item-text col-md-10">
                <p>Ganho de qualidade de vida e valorização imobiliária.
                </p>
              </div>
            </div>
          </div>
          <hr>
          <div class="col-md-12 valores">
            <div class="row">
              <div class="item col-md-2">
                <div class="icones"><!--?xml version="1.0" encoding="UTF-8"?-->
                  <svg preserveAspectRatio="xMidYMid meet" data-bbox="0 0 69.97 69.9" viewBox="0 0 69.97 69.9" xmlns="http://www.w3.org/2000/svg" data-type="shape" role="presentation" aria-hidden="true">
                    <g>
                      <g>
                        <path d="M51 69.9H0V15.84L15.84 0H51a3.29 3.29 0 011.49 3.13v29.63c0 .53 0 1.05.09 1.89 3.71-3.72 7.2-7.1 10.54-10.63 2.81-3 3.87-2.95 6.62.64a1.06 1.06 0 00.23.19v1.25c-.49.59-.93 1.23-1.48 1.78-4.92 4.94-9.84 9.9-14.82 14.79a3.74 3.74 0 00-1.21 3v21.1A3.25 3.25 0 0151 69.9zM17.44 2.24v10.41c0 4.78 0 4.78-4.7 4.78H2.29v50.21h47.89V46.13c-4.41 4.41-8.7 8.54-12.75 12.89a6.06 6.06 0 01-3.17 1.57c-4.36 1.32-3.83-.24-3.11-3.09a5.41 5.41 0 011.68-3.31c2.13-1.76 4-3.81 6-5.74l-.19-.45H13.26a4.29 4.29 0 01-1.53-.15c-.31-.11-.72-.6-.69-.87a1.67 1.67 0 01.76-1c.26-.17.7-.07 1.06-.07h27.18a3 3 0 001.86-.74c2.53-2.43 5-4.91 7.45-7.47a3.66 3.66 0 00.91-2.27c.06-10.51 0-21 0-31.53V2.24zM33.07 58l.57.67a12 12 0 002.47-1.5q15.36-15.29 30.63-30.63c.32-.31.6-.65.66-.72l-1.3-2.16a21.88 21.88 0 01-1.48 1.86Q49.83 40.34 35 55.17A19.9 19.9 0 0033.07 58zM4.14 15.21h11v-11z"></path>
                        <path d="M26.25 26.16h-13.5c-.8 0-1.73.06-1.73-1s.94-1.05 1.73-1.05h26.86c.15 0 .36-.06.46 0 .46.36.89.75 1.34 1.13-.45.31-.89.88-1.34.9-3.11.08-6.21 0-9.32 0z"></path>
                        <path d="M26.26 37.08H13.07A3.45 3.45 0 0111.7 37a1.2 1.2 0 01-.65-.9 1.39 1.39 0 01.64-.92 2.28 2.28 0 011.06-.1h26.84a2 2 0 01.92.06c.35.21.87.62.83.88a1.48 1.48 0 01-.82 1 6 6 0 01-1.85.09z"></path>
                      </g>
                    </g>
                  </svg>
                </div>
              </div>
              <div class="item-text col-md-10">
                <p>Soluções práticas para o dia a dia do seu condomínio.
                </p>
              </div>
            </div>
          </div>
          <hr>
          <div class="col-md-12 valores">
            <div class="row">
              <div class="item col-md-2">
                <div class="icones"><!--?xml version="1.0" encoding="UTF-8"?-->
                  <svg preserveAspectRatio="xMidYMid meet" data-bbox="0.001 104.011 487.022 278.998" viewBox="0.001 104.011 487.022 278.998" xmlns="http://www.w3.org/2000/svg" data-type="shape" role="presentation" aria-hidden="true" aria-labelledby="svgcid-pa5uva-7471wk"><title id="svgcid-pa5uva-7471wk"></title>
                    <g>
                      <path d="M81.554 190.683H50.912a6 6 0 0 0-6 6v42.021a6 6 0 0 0 6 6h30.641a6 6 0 0 0 6-6v-42.021a6 6 0 0 0-5.999-6zm-6 12v3.758H56.912v-3.758h18.642zm-18.642 30.022v-14.264h18.641v14.264H56.912z"></path>
                      <path d="M114.821 244.705h30.642a6 6 0 0 0 6-6v-42.021a6 6 0 0 0-6-6h-30.642a6 6 0 0 0-6 6v42.021a6 6 0 0 0 6 6zm6-12v-14.264h18.642v14.264h-18.642zm18.642-30.022v3.758h-18.642v-3.758h18.642z"></path>
                      <path d="M81.554 255.468H50.912a6 6 0 0 0-6 6v42.022a6 6 0 0 0 6 6h30.641a6 6 0 0 0 6-6v-42.022a5.999 5.999 0 0 0-5.999-6zm-6 12v3.758H56.912v-3.758h18.642zM56.912 297.49v-14.265h18.641v14.265H56.912z"></path>
                      <path d="M226.881 210.685H196.24a6 6 0 0 0-6 6v42.022a6 6 0 0 0 6 6h30.641a6 6 0 0 0 6-6v-42.022a6 6 0 0 0-6-6zm-6 12v3.759H202.24v-3.759h18.641zm-18.641 30.023v-14.264h18.641v14.264H202.24z"></path>
                      <path d="M290.79 210.685h-30.642a6 6 0 0 0-6 6v42.022a6 6 0 0 0 6 6h30.642a6 6 0 0 0 6-6v-42.022a6 6 0 0 0-6-6zm-6 12v3.759h-18.642v-3.759h18.642zm-18.642 30.023v-14.264h18.642v14.264h-18.642z"></path>
                      <path d="M226.881 275.471H196.24a6 6 0 0 0-6 6v42.021a6 6 0 0 0 6 6h30.641a6 6 0 0 0 6-6v-42.021a6 6 0 0 0-6-6zm-6 12v3.758H202.24v-3.758h18.641zm-18.641 30.021v-14.264h18.641v14.264H202.24z"></path>
                      <path d="M341.567 284.844h30.641a6 6 0 0 0 6-6v-42.022a6 6 0 0 0-6-6h-30.641a6 6 0 0 0-6 6v42.022a6 6 0 0 0 6 6zm6-12V258.58h18.641v14.264h-18.641zm18.641-30.023v3.759h-18.641v-3.759h18.641z"></path>
                      <path d="M405.475 284.844h30.642a6 6 0 0 0 6-6v-42.022a6 6 0 0 0-6-6h-30.642a6 6 0 0 0-6 6v42.022a6 6 0 0 0 6 6zm6-12V258.58h18.642v14.264h-18.642zm18.642-30.023v3.759h-18.642v-3.759h18.642z"></path>
                      <path d="M341.567 349.628h30.641a6 6 0 0 0 6-6v-42.021a6 6 0 0 0-6-6h-30.641a6 6 0 0 0-6 6v42.021a6 6 0 0 0 6 6zm6-12v-14.264h18.641v14.264h-18.641zm18.641-30.022v3.758h-18.641v-3.758h18.641z"></path>
                      <path d="M481.822 371.065l-10.657-1.431V220.931h7.85a6 6 0 0 0 6-6v-64.779a6 6 0 0 0-6-6h-139.33v-14.141a6 6 0 0 0-6-6H194.36v-14a6 6 0 0 0-6-6H8.014a6 6 0 0 0-6 6v64.785a6 6 0 0 0 6 6h7.85V308.51l-9.065-1.217c-3.283-.447-6.304 1.864-6.745 5.148a5.999 5.999 0 0 0 5.148 6.744l475.022 63.771a6.002 6.002 0 0 0 6.745-5.148 5.997 5.997 0 0 0-5.147-6.743zm-22.658-3.041l-6.54-.878v-12.134a6 6 0 0 0-6-6h-4.508v-47.405a6 6 0 0 0-6-6h-30.642a6 6 0 0 0-6 6v47.405h-4.501a6 6 0 0 0-6 6v3.589l-63.13-8.475V220.931h133.32v147.093zm-29.047-19.012h-18.642v-41.405h18.642v41.405zm10.508 12v4.523l-33.692-4.523h33.692zm-155.835-32.14h-18.642v-41.401h18.642v41.401zm10.505 12v5.152l-38.378-5.152h38.378zm6-12h-4.505v-47.401a6 6 0 0 0-6-6h-30.642a6 6 0 0 0-6 6v47.401h-4.504a6 6 0 0 0-6 6v4.218l-63.13-8.475V200.802h133.33v147.713l-6.55-.879v-12.763a5.999 5.999 0 0 0-5.999-6.001zm38.389-134.07v-38.649h133.33v52.779h-147.17v-8.13h7.84a6 6 0 0 0 6-6zM194.36 174.797v-38.785h133.324v52.79h-147.17v-8.005h7.846a6 6 0 0 0 6-6zM14.014 116.012H182.36v52.785H21.961c-.032 0-.064-.005-.097-.005s-.064.004-.097.005h-7.753v-52.785zm13.851 64.785h140.65v148.207l-6.55-.879v-13.253a6 6 0 0 0-6-6h-4.502v-47.404a6 6 0 0 0-6-6h-30.642a6 6 0 0 0-6 6v47.404h-4.506a6 6 0 0 0-6 6v4.708l-70.45-9.458V180.797zm111.598 128.075h-18.642v-41.404h18.642v41.404zm-29.148 12h39.65v5.642l-39.65-5.323v-.319z"></path>
                    </g>
                  </svg>
                </div>
              </div>
              <div class="item-text col-md-10">
                <p>Gestão administrativa completa e eficiente.
                </p>
              </div>
            </div>
          </div>


<!--          <hr>-->
<!--          <h4>Visão</h4>-->
<!--          <p>Ser uma empresa de referência no segmento de gestão condominial, oferecendo resultados satisfatório para síndicos-->
<!--            e condôminos.-->
<!--          </p>-->
<!--          <hr>-->
<!--          <h4>Valores</h4>-->
<!--          <p>Comprometimento; Inovação; Ética; Agilidade; Proatividade; Excelência.-->
<!--          </p>-->
          <!--          <ul class="nav nav-tabs">-->
          <!--            <li><a data-target="#menu2" data-toggle="tab" href="#">Valores</a></li>-->
          <!--            <li><a class="active show" data-toggle="tab" data-target="#menu1" href="#menu1">Visão</a></li>-->
          <!--          </ul>-->
          <!--          <div class="tab-content">-->
          <!--            <div id="menu2" class="tab-pane fade">-->
          <!--              <h4>VALORES</h4>-->
          <!--              <p>Comprometimento;-->
          <!--                Inovação;-->
          <!--                Ética;-->
          <!--                Agilidade;-->
          <!--                Proatividade;-->
          <!--                Excelência.</p>-->
          <!--            </div>-->
          <!--            <div id="menu1" class="tab-pane fade active show">-->
          <!--              <h4>VISÃO</h4>-->
          <!--              <p>Ser empresa-->
          <!--                referência no-->
          <!--                segmento de gestão-->
          <!--                condominial,-->
          <!--                oferecendo resultados-->
          <!--                satisfatório para-->
          <!--                síndicos e-->
          <!--                condôminos.</p>-->
          <!--            </div>-->
          <!--          </div>-->


        </div>
      </div>
      <div class="col-lg-6">
        <div class="faq-area">

          <p>A Prime <strong>Engenharia Condominial</strong> é a parceira ideal para síndicos e gestores condominiais, proporcionando suporte técnico completo e consultoria especializada em projetos e gestão de obras.</p>
          <p>Oferecemos uma variedade de serviços personalizados para atender às necessidades específicas de cada condomínio, incluindo auditorias, perícias técnicas, fiscalização de obras, gestão de projetos e agendamento de manutenções.</p>
          <p>Cortesias de pedreiros, pintores e eletricistas. Agende uma visita presencial ou on-line e descubra como podemos fazer a diferença no seu condomínio.</p>




          <!--          <h3>Nossos Serviços</h3>-->
<!--          <div class="pannel-group" id="general-question2">-->
<!--            <div class="card">-->
<!--              <div class="card-header">-->
<!--                <a href="#question1" data-toggle="collapse" data-parent="#general-question2" class="collapsed" aria-expanded="true">-->
<!--                  Modulo Administrativo-->
<!--                </a>-->
<!--              </div>-->
<!--              <div id="question1" class="panel-collapse collapse show" aria-expanded="false" role="banner">-->
<!--                <div class="card-body">-->
<!--                  <ul>-->
<!--                    <li><i class="icofont icofont-arrow-right"></i> Monitoramento da agenda de serviços do condomínio;-->
<!--                    </li>-->
<!--                    <li><i class="icofont icofont-arrow-right"></i> Elaboração de notificações, advertências e multas;-->
<!--                    </li>-->
<!--                    <li><i class="icofont icofont-arrow-right"></i> Orientação sobre aplicabilidade da LGPD no âmbito-->
<!--                      condominial;-->
<!--                    </li>-->
<!--                    <li><i class="icofont icofont-arrow-right"></i> Assessoria em 1 Assembleia extraordinária,-->
<!--                      seja presencial, online ou mista;-->
<!--                    </li>-->
<!--                  </ul>-->
<!--                  <a class="saiba-mais" href="javascript;" routerLink="/servicos">&nbsp; Saiba Mais</a>-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->
<!--            <div class="card">-->
<!--              <div class="card-header">-->
<!--                <a href="#question2" data-toggle="collapse" data-parent="#general-question2" class="collapsed" aria-expanded="false">-->
<!--                  Modulo Contabil e Financeiro-->
<!--                </a>-->
<!--              </div>-->
<!--              <div id="question2" class="panel-collapse collapse" aria-expanded="false" role="banner">-->
<!--                <div class="card-body">-->
<!--                  <ul>-->
<!--                    <li><i class="icofont icofont-arrow-right"></i> Prestação de contas</li>-->
<!--                    <li><i class="icofont icofont-arrow-right"></i> Previsão orçamentária</li>-->
<!--                    <li><i class="icofont icofont-arrow-right"></i> Elaboração de balancete mensal, em formato impresso-->
<!--                      e digital-->
<!--                    </li>-->
<!--                    <li><i class="icofont icofont-arrow-right"></i> Emissão de nada consta (CND)</li>-->
<!--                    <li><i class="icofont icofont-arrow-right"></i> Emissão de boletos</li>-->
<!--                    <li><i class="icofont icofont-arrow-right"></i> Fornecimento de Portal de serviços e App</li>-->
<!--                    <li><i class="icofont icofont-arrow-right"></i> Digitalização e disponibilização do balancete-->
<!--                      contendo toda documentação do condomínio-->
<!--                    </li>-->
<!--                    <li><i class="icofont icofont-arrow-right"></i> Emissão de notas explicativas sobre questionamentos-->
<!--                      do conselho fiscal-->
<!--                    </li>-->
<!--                    <li><i class="icofont icofont-arrow-right"></i> Assembleias ordinárias, seja presencial, online ou-->
<!--                      mista-->
<!--                    </li>-->
<!--                  </ul>-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->
<!--            <div class="card">-->
<!--              <div class="card-header">-->
<!--                <a href="#question3" data-toggle="collapse" data-parent="#general-question2" class="collapsed" aria-expanded="false">-->
<!--                  Modulo de departamento pessoal-->
<!--                </a>-->
<!--              </div>-->
<!--              <div id="question3" class="panel-collapse collapse" aria-expanded="false" role="banner">-->
<!--                <div class="card-body">-->
<!--                  <ul>-->
<!--                     <li><i class="icofont icofont-arrow-right"></i> Recrutamento e seleção de funcionários;</li>-->
<!--                     <li><i class="icofont icofont-arrow-right"></i> Registro de admissões e demissões de colaboradores do condomínio;</li>-->
<!--                     <li><i class="icofont icofont-arrow-right"></i> Formalização de advertências disciplinares e suspensões;</li>-->
<!--                     <li><i class="icofont icofont-arrow-right"></i> Homologação das rescisões dos contratos no Sindicato de classe ou na DRT;</li>-->
<!--                     <li><i class="icofont icofont-arrow-right"></i> Apontamento e elaboração de folha de pagamento;</li>-->
<!--                     <li><i class="icofont icofont-arrow-right"></i> Emissão de todos os encargos trabalhistas (FGTS, INSS, PIS, IR);</li>-->
<!--                     <li><i class="icofont icofont-arrow-right"></i> Ferias, 13° Salário e Rescisões Contratuais;-->
<!--                    Elaboração mensal de folha de pagamento do pró labore do Síndico, bem como as</li>-->
<!--                     <li><i class="icofont icofont-arrow-right"></i> guias de encargos sociais estabelecida em lei (INSS);</li>-->
<!--                     <li><i class="icofont icofont-arrow-right"></i> Controle de vencimentos e períodos aquisitivos de férias;</li>-->
<!--                  </ul>-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->
<!--            <div class="card">-->
<!--              <div class="card-header">-->
<!--                <a href="#question4" data-toggle="collapse" data-parent="#general-question2" class="collapsed" aria-expanded="false">-->
<!--                  Módulo de orçamentos-->
<!--                </a>-->
<!--              </div>-->
<!--              <div id="question4" class="panel-collapse collapse" aria-expanded="false" role="banner">-->
<!--                <div class="card-body">-->
<!--                  <ul>-->
<!--                    <li><i class="icofont icofont-arrow-right"></i>  Analise de todos os contratos de manutenção existentes;</li>-->
<!--                    <li><i class="icofont icofont-arrow-right"></i>  Cotações, com mais de uma empresa para análise de condições de pagamento, melhor material, melhores condições de execução, prazos, etc.;</li>-->
<!--                    <li><i class="icofont icofont-arrow-right"></i>  Seguro Patrimonial Obrigatório;</li>-->
<!--                  </ul>-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->
<!--            <div class="card">-->
<!--              <div class="card-header">-->
<!--                <a href="#question5" data-toggle="collapse" data-parent="#general-question2" class="collapsed" aria-expanded="false">-->
<!--                  Modulo Assessoria e Cobrança-->
<!--                </a>-->
<!--              </div>-->
<!--              <div id="question5" class="panel-collapse collapse" aria-expanded="false" role="banner">-->
<!--                <div class="card-body">-->
<!--                  <ul>-->
<!--                    <li><i class="icofont icofont-arrow-right"></i> Controle de inadimplência.</li>-->
<!--                    <li><i class="icofont icofont-arrow-right"></i> Cobrança administrativa dos inadimplentes.</li>-->
<!--                    <li><i class="icofont icofont-arrow-right"></i> Consultas e orientações relacionadas ao condomínio.-->
<!--                    </li>-->
<!--                    <li><i class="icofont icofont-arrow-right"></i> Elaboração de Contratos de Prestadores de Serviços e-->
<!--                      Confissões de Dividas.-->
<!--                    </li>-->
<!--                    <li><i class="icofont icofont-arrow-right"></i> Participações em reuniões e assembleias, envolvendo-->
<!--                      questões jurídicas pertinentes ao condomínio.-->
<!--                    </li>-->
<!--                  </ul>-->
<!--                  <a class="saiba-mais" href="javascript;" routerLink="/servicos">&nbsp; Saiba Mais</a>-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->
<!--            <div class="card">-->
<!--              <div class="card-header">-->
<!--                <a href="#question6" data-toggle="collapse" data-parent="#general-question2" class="collapsed" aria-expanded="false">-->
<!--                  Modulo Técnico em Arquitetura e Engenharia-->
<!--                </a>-->
<!--              </div>-->
<!--              <div id="question6" class="panel-collapse collapse" aria-expanded="false" role="banner">-->
<!--                <div class="card-body">-->
<!--                  <ul>-->
<!--                    <li><i class="icofont icofont-arrow-right"></i> Assessoria na contratação de serviços técnicos.</li>-->
<!--                    <li><i class="icofont icofont-arrow-right"></i> Fiscalização de obras.</li>-->
<!--                    <li><i class="icofont icofont-arrow-right"></i> Análise de documentação técnica apresentada por-->
<!--                      condômino (NBR 16.280/14).-->
<!--                    </li>-->
<!--                    <li><i class="icofont icofont-arrow-right"></i> Orientação e elaboração de laudos de reformas em-->
<!--                      apartamentos.-->
<!--                    </li>-->
<!--                    <li><i class="icofont icofont-arrow-right"></i> Laudo de Inspeção predial.</li>-->
<!--                    <li><i class="icofont icofont-arrow-right"></i> Laudos técnicos para atendimento de demandas-->
<!--                      jurídicas.-->
<!--                    </li>-->
<!--                    <li><i class="icofont icofont-arrow-right"></i> Analise de projetos para Liberação de obras das-->
<!--                      Unidades.-->
<!--                    </li>-->
<!--                  </ul>-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->

<!--          </div>-->
          <!--          <div class="row">-->
          <!--            <div class="col-sm-4 col-6">-->
          <!--              <div class="counter-box">-->
          <!--                <i class="icofont icofont-user-alt-5"></i>-->
          <!--                <h3 class="counter">269</h3>-->
          <!--                <p>Happy Customers</p>-->
          <!--              </div>-->
          <!--            </div>-->
          <!--            <div class="col-sm-4 col-6">-->
          <!--              <div class="counter-box">-->
          <!--                <i class="icofont icofont-dart"></i>-->
          <!--                <h3 class="counter">25</h3>-->
          <!--                <p>Years Experience</p>-->
          <!--              </div>-->
          <!--            </div>-->
          <!--            <div class="col-sm-4">-->
          <!--              <div class="counter-box d-none d-sm-block">-->
          <!--                <i class="icofont icofont-award"></i>-->
          <!--                <h3 class="counter">50</h3>-->
          <!--                <p>Award Winner</p>-->
          <!--              </div>-->
          <!--            </div>-->
          <!--          </div>-->
        </div>
      </div>
    </div>
  </div>
</section>
