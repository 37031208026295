<app-breadcrumb></app-breadcrumb>
<section class="portfolio-area ptb-100">
  <div class="container">
    <div class="row portfolio-item" id="image-popups">
      <div class="col-lg-3 col-sm-6" *ngFor="let i of [1,2,3,4,5,6,7,8,9,10,11,12,13,14]; ">
        <div class="single-portfolio-box " [style]="'background-image: url(assets/img/obras/'+  i +'.png);'">
          <div class="portfolio-box-overlay">
            <div class="display-table">
              <div class="display-tablecell">
                <a class="theme-btn theme-btn6 image-popup-vertical-fit" [href]="'assets/img/obras/'+ i +'.png'">
                  Visualizar
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
