import {AfterViewInit, Component, OnInit} from '@angular/core';
import {Util} from './../../../shared/util';
declare var google: any;

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit, AfterViewInit {

  constructor() {
  }

  ngOnInit(): void {
    Util.scrollTop();
  }

  ngAfterViewInit(): void {
    this.initMap();
  }

  private initMap(): void {
    const uluru = {lat: -19.8701911, lng: -44.9773732};
    const map = new google.maps.Map(document.getElementById('map'), {
      zoom: 6,
      center: uluru
    });
    const marker = new google.maps.Marker({
      position: uluru,
      map
    });
  }
}
