import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {PartialsModule} from './views/partials/partials.module';
import {ObrasRealizadasComponent} from './views/pages/obras-realizadas/obras-realizadas.component';
import {HttpClientModule} from '@angular/common/http';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import { HomeComponent } from './views/pages/home/home.component';
import { ContatosComponent } from './views/pages/contatos/contatos.component';
import { ServicosComponent } from './views/pages/servicos/servicos.component';
import { ObrasEmAndamentoComponent } from './views/pages/obras-em-andamento/obras-em-andamento.component';
import { QuemSomosComponent } from './views/pages/quem-somos/quem-somos.component';
import { EmConstrucaoComponent } from './views/pages/em-construcao/em-construcao.component';
import { PlanosComponent } from './views/pages/planos/planos.component';

@NgModule({
  declarations: [
    AppComponent,
    ObrasRealizadasComponent,
    HomeComponent,
    ContatosComponent,
    ServicosComponent,
    ObrasEmAndamentoComponent,
    QuemSomosComponent,
    EmConstrucaoComponent,
    PlanosComponent,
  ],
  imports: [
    BrowserAnimationsModule,
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    PartialsModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule {
}
