<div id="map"></div>
<footer class="footer">
  <div class="container">
    <div class="row">
      <div class="col-lg-5 col-md-6">
        <div class="widget">
          <a href="index.html"><img src="assets/img/footer-logo.png" alt="logo"></a>
          <p>Promover uma gestão condominial de forma eficiente, ofertando os melhores serviços, visando auxiliar síndicos em uma administração de excelência, com reduções de custos e ganho de qualidade.</p>
          <div class="widget-social-icons">
            <a href="#"><i class="icofont icofont-social-facebook"></i></a>
            <a href="#"><i class="icofont icofont-social-twitter"></i></a>
            <a href="#"><i class="icofont icofont-social-pinterest"></i></a>
            <a href="#"><i class="icofont icofont-social-dribbble"></i></a>
            <a href="#"><i class="icofont icofont-social-google-plus"></i></a>
          </div>
        </div>
      </div>
      <div class="col-lg-3 col-md-6">
<!--        <div class="widget">-->
<!--          <h4 class="widget-title">G Prime</h4>-->
<!--          <ul>-->
<!--            <app-menu-item></app-menu-item>-->
<!--          </ul>-->
<!--        </div>-->
      </div>
      <div class="col-lg-4 col-md-6">
        <div class="widget">
          <h4 class="widget-title">Entre em contato</h4>
          <ul>
            <li><a [href]="'tel:' + info?.celular"><i class="icofont icofont-location-pin"></i>{{info?.endereco}}</a></li>
            <li><a [href]="'tel:' + info?.telefone"><i class="icofont icofont-telephone"></i> {{info?.telefone}} </a></li>
            <li><a [href]="'tel:' + info?.celular"><i class="icofont icofont-mobile-phone"></i> {{info?.celular}} </a></li>
            <li><a [href]="'mailto:' + info?.email"><i class="icofont icofont-envelope"></i> {{info?.email}} </a></li>
          </ul>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-12">
        <div class="copyright">
          <p>{{getAno()}} &copy; Todos os direitos reservados por: <strong> GPrime Service - Administração de condomínios</strong> | Desenvolvido por: <a [href]="'http://www.agenciacriamais.com.br/'"><i class="icofont icofont-web"></i> www.agenciacriamais.com.br </a></p>
        </div>
      </div>
    </div>
  </div>
</footer>
