<section class="call-back-area service-bottom2 ptb-100">
  <div class="container">
    <div class="row">
      <div class="col-lg-4">
        <div class="single-call-back">
          <h2>Receba uma <span>ligação</span> de volta</h2>
          <p>Deixe seu Número que ligaremos para você em Breve para tirar todas as suas duvidas.</p>
        </div>
      </div>
      <div class="col-lg-8">
        <div class="contact-form">
          <form action="mailto:#" method="POST">
            <div class="row">
              <div class="col-md-6">
                <input type="text" placeholder="Digite seu Nome">
                <input type="email" placeholder="Digite seu Email">
                <input type="email" placeholder="Digite seu Telefone">
<!--                <select>-->
<!--                  <option value="help">Orçamentos</option>-->
<!--                  <option value="help">Dúvidas</option>-->
<!--                  <option value="help">Sugestões / Reclamações</option>-->
<!--                </select>-->
              </div>
              <div class="col-md-6">
                <textarea placeholder="Mensagem"></textarea>
                <button type="submit" name="submit" class="theme-btn theme-btn5">SOLICITE UMA COTAÇÃO <i class="icofont icofont-send-mail"></i></button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</section>
