<!--<app-header></app-header>-->
<!--<app-menubar></app-menubar>-->
<app-slide-principal></app-slide-principal>
<router-outlet></router-outlet>
<app-about></app-about>
<!--<app-services></app-services>-->
<!--<app-servicos></app-servicos>-->
<app-receba-ligacao></app-receba-ligacao>
<app-etapas></app-etapas>
<!--<app-slider-cliente></app-slider-cliente>-->
<!--<div id="map"></div>--> <!--Coloquei no footer -->
