<div class="partner-area ptb-30">
  <div class="container">
    <div class="row">
      <div class="col-lg-12">
        <div class="sec-title mb-20">
          <h2>Clientes</h2>
        </div>
        <div class="partner-slider">
          <div class="partner-single-slide"  *ngFor="let i of [1,2,3,4,5,6,7,8,9,10,11,12]">
            <div class="display-table">
              <div class="display-tablecell">
                <img [src]="'assets/img/clientes/' + i + '.png'" alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
