<div class="menubar">
  <div class="container">
    <div class="row menu-area">
      <div class="col-md-10 col-8">
        <div class="responsive-menu"></div>
        <div class="mainmenu">
          <ul id="primary-menu">
            <app-menu-item></app-menu-item>
<!--            <li *ngFor="let m of menu; let i = index" [ngClass]="{active: m.active}" >-->
<!--              <a href="javascript;" [routerLink]="m.routerLink" (click)="onClick(m)">{{m?.description}}</a>-->
<!--            </li>-->

<!--            <li class="active"><a href="javascript;" routerLink="/">Ínicioooooo</a></li>-->
<!--            <li><a href="javascript;" routerLink="/quem-somos">Quem somos? <i class="icofont icofont-simple-down"></i></a>-->
<!--              <ul>-->
<!--                <li><a href="javascript;" routerLink="/quem-somos">História</a></li>-->
<!--              </ul>-->
<!--            </li>-->
<!--            <li><a href="javascript;" routerLink="/obras-em-andamento">Planos</a></li>-->
<!--            <li><a href="javascript;" routerLink="/servicos">Serviços</a></li>-->
<!--            <li><a href="javascript;" routerLink="/contato">Contato</a></li>-->
               <li><a href="https://www.sistemaacol.com.br/entrar/" target="_blank">Área do Condominio</a></li>
          </ul>
        </div>
      </div>
      <div class="col-md-2 col-4">
        <div class="menu-right">
          <button type="submit" class="toggle-pade">
            <i class="icofont icofont-search"></i>
          </button>
          <form class="navbar-form" role="search">
            <input type="text" placeholder="Pesquisar">
          </form>
          <a class="menubar-icon" href="#"><i class="icofont icofont-navigation-menu"></i></a>
          <div class="offcanvas-menu">
            <span class="offcanvasmenu-close"><i class="icofont icofont-close-line"></i></span>
            <ul>
              <app-menu-item></app-menu-item>
<!--              <li *ngFor="let m of menu; let i = index" [ngClass]="{active: m.active}" >-->
<!--                <a href="javascript;" [routerLink]="m.routerLink" (click)="onClick(m)">{{m?.description}}</a>-->
<!--              </li>-->

<!--              <li class="active"><a href="javascript;" routerLink="/">Ínicio</a>-->
<!--              </li>-->
<!--              <li><a href="javascript;" routerLink="/quem-somos">Quem somos? <i class="icofont icofont-simple-down"></i></a>-->
<!--                <ul>-->
<!--                  <li><a href="javascript;" routerLink="/quem-somos">História</a></li>-->
<!--                </ul>-->
<!--              </li>-->
<!--              <li><a href="javascript;" routerLink="/obras-em-andamento">Obras em Andamento</a></li>-->
<!--              <li><a href="javascript;" routerLink="/obras-realizadas">Obras em Realizadas</a></li>-->
<!--              <li><a href="javascript;" routerLink="/servicos">Serviços</a></li>-->
<!--              <li><a href="javascript;" routerLink="/contato">Contato</a></li>-->
<!--              <li><a href="javascript;" routerLink="/">Área do cliente</a></li>-->
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
