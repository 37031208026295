<app-breadcrumb></app-breadcrumb>
<section class="portfolio-area">
<!--  <div class="container">-->
<!--    <main class="bd-main order-1">-->
<!--&lt;!&ndash;      <div class="bd-intro ps-lg-4">&ndash;&gt;-->
<!--&lt;!&ndash;        <div class="d-md-flex flex-md-row-reverse align-items-center justify-content-between">&ndash;&gt;-->
<!--&lt;!&ndash;          <a class="btn btn-sm btn-bd-light mb-2 mb-md-0"&ndash;&gt;-->
<!--&lt;!&ndash;             href="./assets/img/global_prime.pdf"&ndash;&gt;-->
<!--&lt;!&ndash;             title="View and edit this file on GitHub" target="_blank" rel="noopener">&ndash;&gt;-->
<!--&lt;!&ndash;            PDF&ndash;&gt;-->
<!--&lt;!&ndash;          </a>&ndash;&gt;-->
<!--&lt;!&ndash;          <h1 class="bd-title" id="content">PLANOS PRIME</h1>&ndash;&gt;-->
<!--&lt;!&ndash;        </div>&ndash;&gt;-->
<!--&lt;!&ndash;        <hr>&ndash;&gt;-->
<!--&lt;!&ndash;        <p> A <strong>GLOBAL PRIME</strong>, é fomentada da necessidade de uma gestão de excelência na administração de&ndash;&gt;-->
<!--&lt;!&ndash;          condomínios. </p>&ndash;&gt;-->
<!--&lt;!&ndash;        <p>Quadro técnico extremamente qualificado para que a gestão do sindico e o bem-estar do condômino sejam sempre&ndash;&gt;-->
<!--&lt;!&ndash;          priorizados.</p>&ndash;&gt;-->
<!--&lt;!&ndash;        <p> Além disso, dispomos de tecnologia de ponta que favorece uma administração eficiente e transparente.</p>&ndash;&gt;-->
<!--&lt;!&ndash;        <p> Ofertamos um grande leque de serviços, desde administração de condomínios, contabilidade, engenharia&ndash;&gt;-->
<!--&lt;!&ndash;          condominial até a conservação e limpeza.</p>&ndash;&gt;-->
<!--&lt;!&ndash;        <p> GLOBAL PRIME, nosso conhecimento, seus resultados.</p>&ndash;&gt;-->
<!--&lt;!&ndash;      </div>&ndash;&gt;-->
<!--    </main>-->
<!--  </div>-->
</section>
<app-etapas></app-etapas>

