import {Component, OnInit} from '@angular/core';
import {Menu, MenuService} from '../menu.service';

@Component({
  selector: 'app-menu-item',
  templateUrl: './menu-item.component.html',
  styleUrls: ['./menu-item.component.scss']
})
export class MenuItemComponent implements OnInit {

  get menu(): Array<Menu> {
    return this.menuService.menu;
  }

  constructor(public menuService: MenuService) {
  }

  ngOnInit(): void {
    this.menuService.getMenu().subscribe(menu => this.menuService.menu = menu);
  }

  public onClick(menu: Menu) {
    const i = this.menuService.menu.indexOf(menu);
    this.menuService.menu = this.menuService.menu.map((r: Menu) => {
      return {
        ...r, active: false
      }
    });
    this.menuService.menu[i].active = true;
  }

}
