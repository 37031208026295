<header class="header-top">
  <div class="container">
    <div class="row">
      <div class="col-lg-5">
        <div class="header-top-content text-lg-left text-center">
          <ul>
            <li><a [href]="'tel:' + info?.celular"><i class="icofont icofont-mobile-phone"></i> {{info?.celular}} </a></li>
            <li><a [href]="'mailto:' + info?.email"><i class="icofont icofont-envelope"></i> {{info?.email}} </a></li>
          </ul>
        </div>
      </div>
      <div class="col-lg-7">
        <div class="header-top-content text-lg-right">
          <div class="social-icons">
            <a href="https://www.instagram.com/globalprimecondominios/"><i class="icofont icofont-social-instagram"></i></a>
<!--            <a href="#"><i class="icofont icofont-social-twitter"></i></a>-->
<!--            <a href="#"><i class="icofont icofont-social-pinterest"></i></a>-->
<!--            <a href="#"><i class="icofont icofont-social-dribble"></i></a>-->
<!--            <a href="#"><i class="icofont icofont-social-google-plus"></i></a>-->
          </div>
<!--          <ul>-->
<!--            <li><a href="login.html"><i class="icofont icofont-user"></i> Login</a></li>-->
<!--            <li><a href="register.html"><i class="icofont icofont-pencil-alt-2"></i> Register</a></li>-->
<!--          </ul>-->
<!--          <select>-->
<!--            <option>E-mail</option>-->
<!--            <option>Ban</option>-->
<!--          </select>-->
        </div>
      </div>
    </div>
  </div>
</header>
<section class="header-bottom">
  <div class="container">
    <div class="row">
      <div class="col-lg-4">
        <div class="logo text-lg-left text-center">
          <a href="javascript;" routerLink="/"><img src="assets/img/logo.png" alt="logo"></a>
        </div>
      </div>
      <div class="col-lg-8">
        <div class="header-bottom-right text-lg-right text-center">
          <ul>
            <li>
              <div class="header-info-item flexbox-center m-3">
                <div class="info-icon">
                  <i class="icofont icofont-phone"></i>
                </div>
                <div class="info-text">
                  <h3>
                    <a [href]="'tel:' + info?.telefone">{{info?.telefone}} </a>
                  </h3>
                  <p>Atendimento</p>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</section><!-- header section end -->
