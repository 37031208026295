import {Component, OnInit} from '@angular/core';
import {environment} from '../../../../environments/environment';
import { MenuService, Menu } from '../menubar/menu.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})

/**
 *
 * @author Jean Paul <jeanpaulwebb@gmail.com>
 * @class FooterComponent
 * @date 06/04/2021
 */
export class FooterComponent implements OnInit {
  public info = environment.info;
  //public menu: Menu[] = [];

  constructor(private menuService: MenuService) {
  }

  // public onClick(menu: Menu) {
  //   const i =  this.menu.indexOf(menu);
  //   this.menu = this.menu.map((r: Menu)=>{
  //     return {
  //       ...r, active: false
  //     }
  //   });
  //   this.menu[i].active = true;
  // }

  public getAno(): number {
    const date = new Date();
    return date.getFullYear();
  }

  ngOnInit(): void {
   // this.menuService.getMenu().subscribe(menu => this.menu = menu);
  }

}
