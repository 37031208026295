<app-breadcrumb></app-breadcrumb>
<section class="portfolio-area ptb-100">
  <div class="container">
    <main class="bd-main order-1">
      <div class="bd-intro ps-lg-4">
        <div class="d-md-flex flex-md-row-reverse align-items-center justify-content-between">
          <a class="btn btn-sm btn-bd-light mb-2 mb-md-0"
             href="./assets/img/global_prime.pdf"
             title="View and edit this file on GitHub" target="_blank" rel="noopener">
            PDF
          </a>
          <h1 class="bd-title" id="content">GLOBAL PRIME</h1>
        </div>
        <hr>
        <p>A <strong>GLOBAL PRIME</strong> é fomentada da necessidade de uma gestão de excelência em serviços de engenharia para condomínios. </p>
        <p>Quadro técnico extremamente qualificado para que a gestão do síndico e o bem-estar do condômino sejam sempre priorizados. </p>
        <p>Além disso, dispomos de tecnologia de ponta que favorece uma administração eficiente e transparente. </p>
        <p> GLOBAL PRIME, nosso conhecimento, seus resultados. </p>
        <hr>
        <h4>Missão</h4>
        <p> Ter sempre os melhores serviços de engenharia, visando auxiliar síndicos em uma administração de excelência, com reduções de custos e ganho de qualidade.</p>
        <hr>
        <h4>Visão</h4>
        <p>Ser uma empresa de referência no segmento de engenharia condominial, oferecendo resultados satisfatórios para síndicos e condôminos.
        </p>
        <hr>
        <h4>Valores</h4>
        <p>Comprometimento, inovação, ética, transparência, proatividade e excelência em todos os nossos serviços.
        </p>
      </div>
    </main>
  </div>
</section>




