import {AfterViewInit, Component, OnInit} from '@angular/core';
declare var $: any;
@Component({
  selector: 'app-slide-principal',
  templateUrl: './slide-principal.component.html',
  styleUrls: ['./slide-principal.component.scss']
})
export class SlidePrincipalComponent implements OnInit, AfterViewInit {

  constructor() { }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    this.init();
  }

  private init(): void {
    $('.hero-area-slider').owlCarousel({
      items: 1,
      loop: false,
      autoplay: false,
      autoplayTimeout: 4000,
      dots: false,
      nav: false,
      navText: ['<i class=\'icofont icofont-curved-left\'></i>', '<i class=\'icofont icofont-curved-right\'></i>'],
    });
  }
}
