import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-services',
  templateUrl: './services.component.html',
  styleUrls: ['./services.component.scss']
})
export class ServicesComponent implements OnInit {

  public state: any = {};

  constructor() {
  }

  ngOnInit(): void {
    this.initState();
  }

  public fnSubstr(id: number): void {
    const bkpSearchItem = this.state.bkpServices.find((item: { id: number; }) => item.id === id);
    const searchItem = this.state.services.find((item: { id: number; }) => item.id === id);
    let count = 0;
    searchItem.open = !searchItem.open;
    if (searchItem.hasOwnProperty('description')) {
      count = +bkpSearchItem.description.length;
    }
    if (!searchItem.open) {
      count = 100;
    }
    searchItem.description = bkpSearchItem.description.substr(0, count);
  }

  private initState(): void {
    this.state = {
      services: [
        {
          id: 1,
          title: 'Administrativo',
          description: 'Monitoramento da agenda de serviços do condomínio, Elaboração de notificações, advertências e multas, Orientação aos Síndicos(as) e Conselheiros(as) nas questões administrativas diárias.Monitoramento da agenda de serviços do condomínio, Elaboração de notificações, advertências e multas, Orientação aos Síndicos(as) e Conselheiros(as) nas questões administrativas diárias.',
          url: '#'
        },
        {
          id: 2,
          title: 'Contabil e financeiro',
          description: 'Prestação de contas, Previsão orçamentária, Elaboração de balancete mensal, em formato impresso e digital, Emissão de nada consta (CND), Emissão de boletos, Fornecimento de Portal de serviços e App.',
          url: '#'
        },
        {
          id: 3,
          title: 'Departamento pessoal',
          description: 'Recrutamento e seleção de funcionários, Registro de admissões e demissões de colaboradores do condomínio, Formalização de advertências disciplinares e suspensões, Homologação das rescisões dos contratos no Sindicato de classe ou na DRT',
          url: '#'
        },
        {
          id: 4,
          title: 'Módulo de orçamentos',
          description: 'Analise de todos os contratos de manutenção existentes, Seguro Patrimonial Obrigatório, Controle de inadimplência',
          url: '#'
        },
        {
          id: 5,
          title: 'Cotações',
          description: 'Cotações, com mais de uma empresa para análise de condições de pagamento, melhor material, melhores condições de execução, prazos, etc.',
          url: '#'
        },
        {
          id: 7,
          title: 'Analise contratual',
          description: 'Antes do fechamento de contrato com prestadores de serviços, garantindo idoneidade das empresas participantes.',
          url: '#'
        },
      ]
    };
    this.state.bkpServices = Object.assign([], this.state.services);
    const item = this.state.bkpServices.map((data: any) => {
      const r = {...data, open: false};
      r.description = r.description.substr(0, 100);
      return r;
    });
    this.state.services = Object.assign([], item);
  }
}
