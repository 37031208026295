import {AfterViewInit, Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss']
})
export class BreadcrumbComponent implements OnInit, AfterViewInit {
  public params: any;
  constructor(private activatedRoute: ActivatedRoute) {
  }

  ngOnInit(): void {
    this.init();
  }

  ngAfterViewInit(): void {

  }

  private init(): void {
    this.activatedRoute.data.subscribe(params => this.params = params);
  }
}
