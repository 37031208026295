import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-etapas',
  templateUrl: './etapas.component.html',
  styleUrls: ['./etapas.component.scss']
})
export class EtapasComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
