<!--<div id="preloader"></div>-->
<!--<app-header></app-header>-->
<!--<app-menubar></app-menubar>-->
<!--&lt;!&ndash;<app-slide-principal></app-slide-principal>&ndash;&gt;-->
<!--<router-outlet></router-outlet>-->
<!--&lt;!&ndash;<app-about></app-about>&ndash;&gt;-->
<!--&lt;!&ndash;<app-services></app-services>&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;<app-servicos></app-servicos>&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;<app-receba-ligacao></app-receba-ligacao>&ndash;&gt;-->
<!--&lt;!&ndash;<app-etapas></app-etapas>&ndash;&gt;-->
<!--&lt;!&ndash;<app-slider-cliente></app-slider-cliente>&ndash;&gt;-->
<!--&lt;!&ndash;<div id="map"></div>&ndash;&gt;-->
<!--<app-footer></app-footer>-->

<!--<router-outlet></router-outlet>-->


<div id="preloader"></div>
<app-header></app-header>
<app-menubar></app-menubar>
<!--<app-slide-principal></app-slide-principal>-->
<router-outlet></router-outlet>
<!--<app-about></app-about>-->
<!--<app-services></app-services>-->
<!--&lt;!&ndash;<app-servicos></app-servicos>&ndash;&gt;-->
<!--<app-receba-ligacao></app-receba-ligacao>-->
<!--<app-etapas></app-etapas>-->
<!--<app-slider-cliente></app-slider-cliente>-->
<!--<div id="map"></div>-->
<app-whatsapp></app-whatsapp>
<app-footer></app-footer>
