import {AfterViewInit, Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router, RouterState} from '@angular/router';

declare const jQuery: any;
declare const $: any;

@Component({
  selector: 'app-obras-realizadas',
  templateUrl: './obras-realizadas.component.html',
  styleUrls: ['./obras-realizadas.component.scss']
})
export class ObrasRealizadasComponent implements OnInit, AfterViewInit {

  constructor() {
  }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    this.imagePopus();
  }

  private imagePopus(): void {
    $('.image-popup-vertical-fit').magnificPopup({
      type: 'image',
      closeOnContentClick: true,
      mainClass: 'mfp-img-mobile',
      image: {
        verticalFit: true
      }
    });
  }
}
