import {AfterViewInit, Component, OnInit} from '@angular/core';
import {environment} from '../environments/environment.prod';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, AfterViewInit {

  public production = environment.production;

  constructor() {
  }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
  }
}
