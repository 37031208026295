import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable, of} from 'rxjs';

export interface Menu {
  routerLink: string;
  description: string;
  active: boolean;
  subMenu?: Array<{
    routerLink: string,
    description: string,
    active: boolean
  }>;
}

@Injectable({
  providedIn: 'root'
})
export class MenuService {

  public menu: Menu[] = [];

  constructor(private http: HttpClient) {
  }

  getMenu(): Observable<Menu[]> {
    return of(MENU_MOCKUP);
  }
}

export const MENU_MOCKUP: Menu[] = [
  {
    routerLink: '/',
    description: 'Ínicio',
    active: true
  },
  {
    routerLink: '/quem-somos',
    description: 'Quem somos',
    active: false,
    subMenu: [
      {
        routerLink: '/quem-somos',
        description: 'História',
        active: false
      }
    ]
  },
  {
    routerLink: '/servicos',
    description: 'Serviços',
    active: false
  },
  {
    routerLink: '/planos',
    description: 'Planos',
    active: false
  }
];
