<section class="service-area ptb-100">
  <div class="container">
    <div class="row">
      <div class="col-lg-12">
        <div class="sec-title mb-20">
          <h2>Por que somos a <span class="title-span">escolha</span> certa
            <span class="sec-title-border">
									<span></span>
									<span></span>
									<span></span>
								</span>
          </h2>
          <p>Texto Texto Texto Texto Texto Texto Texto Texto Texto Texto Texto Texto Texto Texto Texto Texto Texto .</p>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-4">
        <div class="single-service-box">
          <img src="assets/img/service/service1.jpg" alt="service" />
          <div class="service-box-overlay">
            <i class="icofont icofont-light-bulb"></i>
          </div>
          <div class="service-box-hover">
            <div class="display-table">
              <div class="display-tablecell">
                <i class="icofont icofont-light-bulb"></i>
                <h4>Business Plan</h4>
                <p>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam,</p>
                <a href="#" class="theme-btn theme-btn6">Read More</a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-4">
        <div class="single-service-box">
          <img src="assets/img/service/service2.jpg" alt="service" />
          <div class="service-box-overlay">
            <i class="icofont icofont-brand-att"></i>
          </div>
          <div class="service-box-hover">
            <div class="display-table">
              <div class="display-tablecell">
                <i class="icofont icofont-light-bulb"></i>
                <h4>Business Plan</h4>
                <p>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam,</p>
                <a href="#" class="theme-btn theme-btn6">Read More</a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-4">
        <div class="single-service-box">
          <img src="assets/img/service/service3.jpg" alt="service" />
          <div class="service-box-overlay">
            <i class="icofont icofont-apple"></i>
          </div>
          <div class="service-box-hover">
            <div class="display-table">
              <div class="display-tablecell">
                <i class="icofont icofont-light-bulb"></i>
                <h4>Business Plan</h4>
                <p>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam,</p>
                <a href="#" class="theme-btn theme-btn6">Read More</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
