import {AfterViewInit, Component, OnInit} from '@angular/core';

declare const $: any;

@Component({
  selector: 'app-slider-cliente',
  templateUrl: './slider-cliente.component.html',
  styleUrls: ['./slider-cliente.component.scss']
})
/**
 * SOU-AÇO (SliderClienteComponent)
 *
 * @author Jean Paul <jeanpaulwebb@gmail.com>
 * @class SliderClienteComponent
 * @date 06/04/2021
 */
export class SliderClienteComponent implements OnInit, AfterViewInit {

  constructor() {
  }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    this.init();
  }

  private init(): void {
    $('.partner-slider').owlCarousel({
      dots: false,
      loop: true,
      autoplay: true,
      autoplayTimeout: 4000,
      margin: 30,
      items: 1,
      nav: false,
      responsive: {
        420: {
          items: 2,
        },
        768: {
          items: 3,
        },
        992: {
          items: 4,
        }
      }
    });
  }
}
