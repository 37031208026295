<app-breadcrumb></app-breadcrumb>
<section class="portfolio-area ptb-100">
  <div class="container">
    <main class="bd-main order-1">
      <div class="bd-intro ps-lg-4">
        <div class="d-md-flex flex-md-row-reverse align-items-center justify-content-between">
<!--          <a class="btn btn-sm btn-bd-light mb-2 mb-md-0"-->
<!--             href="./assets/img/global_prime.pdf"-->
<!--             title="View and edit this file on GitHub" target="_blank" rel="noopener">-->
<!--            PDF-->
<!--          </a>-->
        </div>
        <div class="card-body modulo">
          <h4 class="title">ENGENHARIA CONDOMINIAL</h4>
          <ul>
            <li><i class="icofont icofont-arrow-right"></i> Assessoria na contratação de serviços técnicos;</li>
            <li><i class="icofont icofont-arrow-right"></i> Fiscalização de obras;</li>
            <li><i class="icofont icofont-arrow-right"></i> Análise de documentação técnica apresentada por condômino (NBR 16.280/14);</li>
            <li><i class="icofont icofont-arrow-right"></i> Orientação e elaboração de laudos de reformas em apartamentos;</li>
            <li><i class="icofont icofont-arrow-right"></i> Laudo de Inspeção predial;</li>
            <li><i class="icofont icofont-arrow-right"></i> Laudos técnicos para atendimento de demandas jurídicas;</li>
            <li><i class="icofont icofont-arrow-right"></i> Gestão de Manutenção Preventiva e Corretiva;</li>
            <li><i class="icofont icofont-arrow-right"></i> Realização de pequenos reparos hidráulicos e elétricos;</li>
            <li><i class="icofont icofont-arrow-right"></i> Analise de projetos para Liberação de obras das Unidades;</li>
            <li><i class="icofont icofont-arrow-right"></i> Cotações orçamentarias de obras e serviços;</li>
            <li><i class="icofont icofont-arrow-right"></i> Elaboração de editais de tomada preços para realização de obras e serviços;</li>
            <li><i class="icofont icofont-arrow-right"></i> Acompanhamento de AVCB (auto de vistoria do Corpo de Bombeiros);</li>
            <li><i class="icofont icofont-arrow-right"></i> Acompanhamento quanto a validade dos laudos de GLP (estanqueidade), SPDA (para-raio), entre outros que devem ser renovados anualmente.;</li>
            <li><i class="icofont icofont-arrow-right"></i> Gerenciamento e Agendamento das Manutençoes Periódicas.</li>
          </ul>
        </div>
<!--        <div class="card-body modulo">-->
<!--          <h4 class="title">MODULO ADMINISTRATIVO</h4>-->
<!--          <ul>-->
<!--            <li><i class="icofont icofont-arrow-right"></i> Monitoramento da agenda de serviços do condomínio;</li>-->
<!--            <li><i class="icofont icofont-arrow-right"></i> Elaboração de notificações, advertências e multas;</li>-->
<!--            <li><i class="icofont icofont-arrow-right"></i> Orientação aos Síndicos(as) e Conselheiros(as) nas questões-->
<!--              administrativas diárias, auxiliando da melhor maneira possível, para uma convivência com base no respeito-->
<!--              mútuo;-->
<!--            </li>-->
<!--            <li><i class="icofont icofont-arrow-right"></i> Orientação sobre aplicabilidade da LGPD no âmbito-->
<!--              condominial;-->
<!--            </li>-->
<!--            <li><i class="icofont icofont-arrow-right"></i> Assessoria em 1 Assembleia extraordinária, seja-->
<!--              presencial, online ou mista;-->
<!--            </li>-->
<!--            <li><i class="icofont icofont-arrow-right"></i> Envio de mensageiros para retirada e entrega de documentos-->
<!--              ao condomínio;-->
<!--            </li>-->
<!--            <li><i class="icofont icofont-arrow-right"></i> Digitação de atas, comunicados, avisos e editais;</li>-->
<!--            <li><i class="icofont icofont-arrow-right"></i> Revisão e registro de Atas em cartório;</li>-->
<!--            <li><i class="icofont icofont-arrow-right"></i> Envio de e-mails com Editais, Atas e comunicados de-->
<!--              interesse do Condomínio;-->
<!--              Cadastramento de todas as unidades e seus respectivos responsáveis, como-->
<!--              proprietário, inquilino e administradoras/imobiliárias, conforme documentos oficiais;-->
<!--            </li>-->
<!--            <li><i class="icofont icofont-arrow-right"></i> Disponibilizar APP moderno e interativo</li>-->
<!--          </ul>-->
<!--        </div>-->
<!--        <div class="card-body modulo">-->
<!--          <h4 class="title">MODULO CONTABIL E FINANCEIRO</h4>-->
<!--          <ul>-->
<!--            <li><i class="icofont icofont-arrow-right"></i> Prestação de contas;</li>-->
<!--            <li><i class="icofont icofont-arrow-right"></i> Previsão orçamentária;</li>-->
<!--            <li><i class="icofont icofont-arrow-right"></i> Elaboração de balancete mensal, em formato impresso e-->
<!--              digital;-->
<!--            </li>-->
<!--            <li><i class="icofont icofont-arrow-right"></i> Emissão de nada consta (CND);</li>-->
<!--            <li><i class="icofont icofont-arrow-right"></i> Emissão de boletos;</li>-->
<!--            <li><i class="icofont icofont-arrow-right"></i> Fornecimento de Portal de serviços e App;</li>-->
<!--            <li><i class="icofont icofont-arrow-right"></i> Digitalização e disponibilização do balancete contendo toda-->
<!--              documentação do condomínio;-->
<!--            </li>-->
<!--            <li><i class="icofont icofont-arrow-right"></i> Emissão de notas explicativas sobre questionamentos do-->
<!--              conselho fiscal;-->
<!--            </li>-->
<!--            <li><i class="icofont icofont-arrow-right"></i> Assembleias ordinárias, seja presencial, online ou mista;-->
<!--            </li>-->
<!--          </ul>-->
<!--        </div>-->
<!--        <div class="card-body modulo">-->
<!--          <h4 class="title">MODULO DE DEPARTAMENTO PESSOAL</h4>-->
<!--          <ul>-->
<!--            <li><i class="icofont icofont-arrow-right"></i> Recrutamento e seleção de funcionários;</li>-->
<!--            <li><i class="icofont icofont-arrow-right"></i> Registro de admissões e demissões de colaboradores do-->
<!--              condomínio;-->
<!--            </li>-->
<!--            <li><i class="icofont icofont-arrow-right"></i> Formalização de advertências disciplinares e suspensões;-->
<!--            </li>-->
<!--            <li><i class="icofont icofont-arrow-right"></i> Homologação das rescisões dos contratos no Sindicato de-->
<!--              classe ou na DRT;-->
<!--            </li>-->
<!--            <li><i class="icofont icofont-arrow-right"></i> Apontamento e elaboração de folha de pagamento;</li>-->
<!--            <li><i class="icofont icofont-arrow-right"></i> Emissão de todos os encargos trabalhistas (FGTS, INSS, PIS,-->
<!--              IR);-->
<!--            </li>-->
<!--            <li><i class="icofont icofont-arrow-right"></i> Ferias, 13° Salário e Rescisões Contratuais;-->
<!--              Elaboração mensal de folha de pagamento do pró labore do Síndico, bem como as-->
<!--            </li>-->
<!--            <li><i class="icofont icofont-arrow-right"></i> guias de encargos sociais estabelecida em lei (INSS);</li>-->
<!--            <li><i class="icofont icofont-arrow-right"></i> Controle de vencimentos e períodos aquisitivos de férias;-->
<!--            </li>-->
<!--            <li><i class="icofont icofont-arrow-right"></i> Elaboração e controle de escalas de férias;</li>-->
<!--            <li><i class="icofont icofont-arrow-right"></i> Elaboração de escalas;</li>-->
<!--            <li><i class="icofont icofont-arrow-right"></i> Analise para contratação de NR’S (Normas Regulamentares-->
<!--              Trabalhistas);-->
<!--            </li>-->
<!--            <li><i class="icofont icofont-arrow-right"></i> Entrega anual de RAIS e DIRF;</li>-->
<!--            <li><i class="icofont icofont-arrow-right"></i> Emissão de guias de retenções tributárias;</li>-->
<!--          </ul>-->
<!--        </div>-->
<!--        <div class="card-body modulo">-->
<!--          <h4 class="title"> MODULO DE ORÇAMENTOS</h4>-->
<!--          <ul>-->
<!--            <li><i class="icofont icofont-arrow-right"></i> Analise de todos os contratos de manutenção existentes;</li>-->
<!--            <li><i class="icofont icofont-arrow-right"></i> Cotações, com mais de uma empresa para análise de condições-->
<!--              de pagamento, melhor material, melhores condições de execução, prazos, etc.;-->
<!--            </li>-->
<!--            <li><i class="icofont icofont-arrow-right"></i> Analise contratual, antes do fechamento de contrato com-->
<!--              prestadores de serviços, garantindo idoneidade das empresas participantes;-->
<!--            </li>-->
<!--          </ul>-->
<!--        </div>-->
<!--        <div class="card-body modulo">-->
<!--          <h4 class="title"> MODULO ASSESSORIA E COBRANÇA </h4>-->
<!--          <ul>-->
<!--            <li><i class="icofont icofont-arrow-right"></i> Controle de inadimplência;</li>-->
<!--            <li><i class="icofont icofont-arrow-right"></i> Cobrança administrativa dos inadimplentes;</li>-->
<!--            <li><i class="icofont icofont-arrow-right"></i> Consultas e orientações relacionadas ao condomínio;</li>-->
<!--            <li><i class="icofont icofont-arrow-right"></i> Elaboração de Contratos de Prestadores de Serviços e-->
<!--              Confissões de Dividas;-->
<!--            </li>-->
<!--            <li><i class="icofont icofont-arrow-right"></i> Participações em reuniões e assembleias, envolvendo questões-->
<!--              jurídicas pertinentes ao condomínio;-->
<!--            </li>-->
<!--            <li><i class="icofont icofont-arrow-right"></i> Mediação e cobrança amigável de débitos condominiais por-->
<!--              meio de aviso de débito-->
<!--              contido nos boletos, ligações telefônicas, cartas e e-mail de notificações de débitos;-->
<!--            </li>-->
<!--            <li><i class="icofont icofont-arrow-right"></i>Negociação de débitos, conforme definições da Convenção, e-->
<!--              Assembleias do condomínio;-->
<!--            </li>-->
<!--            <li><i class="icofont icofont-arrow-right"></i> Após anuência do Sindico(a), possíveis demandas jurídicas-->
<!--              serão encaminhadas ao Departamento Jurídico do condomínio;-->
<!--            </li>-->
<!--          </ul>-->
<!--        </div>-->
<!--        <div class="card-body modulo">-->
<!--          <h4 class="title"> MODULO TÉCNICO EM ARQUITETURA E ENGENHARIA </h4>-->
<!--          <ul>-->
<!--            <li><i class="icofont icofont-arrow-right"></i> Assessoria na contratação de serviços técnicos;</li>-->
<!--            <li><i class="icofont icofont-arrow-right"></i> Fiscalização de obras;</li>-->
<!--            <li><i class="icofont icofont-arrow-right"></i> Análise de documentação técnica apresentada por condômino-->
<!--              (NBR 16.280/14);-->
<!--            </li>-->
<!--            <li><i class="icofont icofont-arrow-right"></i> Orientação e elaboração de laudos de reformas em-->
<!--              apartamentos;-->
<!--            </li>-->
<!--            <li><i class="icofont icofont-arrow-right"></i> Laudo de Inspeção predial;</li>-->
<!--            <li><i class="icofont icofont-arrow-right"></i> Laudos técnicos para atendimento de demandas jurídicas;</li>-->
<!--            <li><i class="icofont icofont-arrow-right"></i> Analise de projetos para Liberação de obras das Unidades;-->
<!--            </li>-->
<!--            <li><i class="icofont icofont-arrow-right"></i> Bombeiro – Monitoramento de validade de laudos (SPDA,-->
<!--              Estanqueidade de central de GLP), quando houver;-->
<!--            </li>-->
<!--          </ul>-->
<!--        </div>-->
        <h5> SEU CONDOMÍNIO SEGUE AS NORMATIVAS QUANDO VAI REFORMAR?</h5>
        <p>
          A NBR 16.280 preconiza que toda reforma só pode começar após a aprovação do plano de reforma pelo síndico. Neste documento deve conter todas as informações referentes às intervenções que serão realizadas. Seguindo as normas, nossos profissionais engenheiros e arquitetos analisam de forma técnica os projetos e a execução da obra, e se a mesma encontra-se apta a ser iniciada de forma segura.
        </p>

      </div>
    </main>
  </div>
</section>



