export const environment = {
  production: true,
  info: {
    email: 'atendimento@gprimeservice.com.br',
    telefone: '(61) 2194-8309',
    celular: '(61) 9 8378-2693',
    whatsapp: '+5561983782693',
    endereco: 'Rua Copaíba Lt 01 Torre B, salas 207 parte A Águas Claras, DF Cep 71919540',
    facebook: '#',
    twitter: '#',
    youtube: '#'
  }
};
