<section class="breadcrumb-area" id="home">
  <div class="container">
    <div class="row">
      <div class="col-lg-12">
        <div class="breadcrumb-area-content">
          <h2>{{params?.title}}</h2>
<!--                    <ul>-->
<!--                      <li><a href="index.html">Home</a></li>-->
<!--                      <li class="active"><a href="project1.html">Project</a></li>-->
<!--                    </ul>-->
        </div>
      </div>
    </div>
  </div>
</section>
