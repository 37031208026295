import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-obras-em-andamento',
  templateUrl: './obras-em-andamento.component.html',
  styleUrls: ['./obras-em-andamento.component.scss']
})
export class ObrasEmAndamentoComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
