import {AfterViewInit, Component, OnInit} from '@angular/core';
import {Menu, MenuService} from './menu.service';

declare var jQuery: any;
declare var $: any;

@Component({
  selector: 'app-menubar',
  templateUrl: './menubar.component.html',
  styleUrls: ['./menubar.component.scss']
})
export class MenubarComponent implements OnInit, AfterViewInit {

  // public menu: Menu[] = [];

  ngAfterViewInit(): void {
  }

  constructor(private menuService: MenuService) {
  }

  ngOnInit(): void {
    //this.menuService.getMenu().subscribe(menu => this.menu = menu);
    this.init();
  }

  private init(): void {
    // jQuery(window).on('scroll', () => {
    //   if ($(this).scrollTop() > 480) {
    //     $('.menubar').addClass('sticky');
    //   } else {
    //     $('.menubar').removeClass('sticky');
    //   }
    // });
    $('.toggle-pade').on('click', () => {
      $('.navbar-form').toggle('slow');
      return false;
    });
    $('.menubar-icon').on('click', () => {
      $('.offcanvas-menu').addClass('active');
      return false;
    });
    $('.offcanvasmenu-close').on('click', () => {
      $('.offcanvas-menu').removeClass('active');
      $('.offcanvas-menu li').removeClass('showul');
    });
    $('.offcanvas-menu li').on('click', () => {
      $(this).toggleClass('showul');
    });


  }

  // public onClick(menu: Menu) {
  //   const i =  this.menu.indexOf(menu);
  //   this.menu = this.menu.map((r: Menu)=>{
  //     return {
  //       ...r, active: false
  //     }
  //   });
  //   this.menu[i].active = true;
  // }
}
