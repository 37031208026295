import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {HeaderComponent} from './header/header.component';
import {MenubarComponent} from './menubar/menubar.component';
import {SlidePrincipalComponent} from './slide-principal/slide-principal.component';
import {AboutComponent} from './about/about.component';
import {ServicesComponent} from './services/services.component';
import {EtapasComponent} from './etapas/etapas.component';
import {RecebaLigacaoComponent} from './receba-ligacao/receba-ligacao.component';
import {FooterComponent} from './footer/footer.component';
import {SliderClienteComponent} from './slider-cliente/slider-cliente.component';
import {ServicosComponent} from './servicos/servicos.component';
import {RouterModule} from '@angular/router';
import {BreadcrumbComponent} from './breadcrumb/breadcrumb.component';
import {MenuItemComponent} from './menubar/menu-item/menu-item.component';
import {WhatsappComponent} from './whatsapp/whatsapp.component';

@NgModule({
  declarations: [
    HeaderComponent,
    MenubarComponent,
    SlidePrincipalComponent,
    AboutComponent,
    ServicesComponent,
    EtapasComponent,
    RecebaLigacaoComponent,
    FooterComponent,
    SliderClienteComponent,
    ServicosComponent,
    BreadcrumbComponent,
    MenuItemComponent,
    WhatsappComponent
  ],
    imports: [
        CommonModule,
        RouterModule
    ],
  exports: [
    HeaderComponent,
    MenubarComponent,
    SlidePrincipalComponent,
    AboutComponent,
    ServicesComponent,
    EtapasComponent,
    RecebaLigacaoComponent,
    FooterComponent,
    SliderClienteComponent,
    ServicosComponent,
    BreadcrumbComponent,
    MenuItemComponent,
    WhatsappComponent
  ]
})
export class PartialsModule { }
