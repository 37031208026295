<section class="service-bottom2 ptb-100">
  <div class="container">
    <div class="row">
      <div class="col-lg-12">
        <div class="sec-title mb-20">
          <h2>Nossos <span class="title-span">Serviços</span>
<!--            <span class="sec-title-border">-->
<!--									<span></span>-->
<!--									<span></span>-->
<!--									<span></span>-->
<!--								</span>-->
          </h2>
<!--          <p>teste.</p>-->
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-4 col-md-6" *ngFor="let service of state?.services; let i = index">
        <div class="service-bottom-box">
          <div class="service-bottom-text">
            <h4>{{service?.title}}</h4>
            <p>{{ service?.description }} {{!service.open ? "..." : ''}}
              <a [hidden]="!service.open" class="saiba-mais" href="javascript;" routerLink="/servicos">&nbsp; Saiba Mais</a>
            </p>
            <a class="theme-btn theme-btn3 leia-mais" (click)="fnSubstr(service?.id)">Leia Mais</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</section><!-- service section end -->
